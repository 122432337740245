<template>
  <div id="activation">
    <v-row class="justify-start">
      <v-col cols="12" sm="12">
        <div class="display-1">
          Activation
          <v-btn
            @click="$router.go(-1)"
            class="float-right btns color-white mr-4"
            color="#94d227"
            elevation="0"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-between">
      <v-col cols="12" sm="6">
        <div class="panel-info flex p-4 mb-4 text-sm  shadow rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
          <svg @click="dropdownAudience=!dropdownAudience" class="cursor-pointer inline flex-shrink-0 mr-3 w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <div>
             <h4 @click="dropdownAudience=!dropdownAudience" class="title font-weight-bold cursor-pointer">Audiences Activation</h4>
             <ul v-if="dropdownAudience">
               <li>Audience Targiting</li>
               <li>Lookalike Audiences</li>
             </ul>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="panel-info flex p-4 mb-4 text-sm  shadow rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
          <svg  @click="dropdownData=!dropdownData" class="cursor-pointer inline flex-shrink-0 mr-3 w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <div>
             <h4 @click="dropdownData=!dropdownData" class="title font-weight-bold cursor-pointer">Audiences Anrichment</h4>
             <ul v-if="dropdownData">
               <li>Personalization
                 <ul>
                   <li>Email</li>
                   <li>Site</li>
                 </ul>
               </li>
               <li>Measurement</li>
               <li>Market Intelligence</li>
             </ul>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="my-12 justify-between">
      <v-col cols="12" sm="6" class="custom-card">
        <v-card class="pa-2 audience">
          <div class="ac-title text-center font-weight-bold my-5">
            <v-icon>mdi-account-multiple</v-icon>
            Audience Navigator
          </div>
          <div class="schema-container">
            <div class="schema-side">
              <div class="schema-box ac-title">
                <span class="schema-box-title font-weight-bold"
                  >Audience Manager</span
                >
                <span class="schema-box-desc"
                  >Create, manage, and deploy, ShareThis audiences</span
                >
              </div>
              <div class="schema-suboxes">
                  <div class="schema-subox">
                <a href="#">
                    <span class="schema-box-title font-weight-bold text-black"
                      >Audience Creator</span
                    >
                    <span class="schema-box-desc text-black"
                      >Create custom audiences</span
                    >
                </a>
                  </div>
                  <div class="schema-subox">
                <a href="#">
                    <span class="schema-box-title font-weight-bold text-black"
                      >Branded Audiences</span
                    >
                    <span class="schema-box-desc text-black"
                      >Manage ShareThis syndicated audiences</span
                    >
                </a>
                  </div>
                  <div class="schema-subox">
                <a href="#">
                    <span class="schema-box-title font-weight-bold text-black"
                      >White Label Audiences</span
                    >
                    <span class="schema-box-desc text-black"
                      >Manage ShareThis white-labeled audiences</span
                    >
                </a>
                  </div>
              </div>
            </div>

            <div class="schema-side">
              <div class="schema-box ac-title">
                <span class="schema-box-title font-weight-bold"
                  >Audience Insights</span
                >
                <span class="schema-box-desc"
                  >Visualize and uncover your audience's key behavioral trends</span
                >
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" class="custom-card">
        <v-card class="pa-2 explorer">
          <div class="ac-title text-center font-weight-bold my-5">
            <v-icon>mdi-chart-pie</v-icon>
            Data Explorer
          </div>
          <div class="schema-container">
            <div class="schema-side">
              <div class="schema-box ac-title">
                <span class="schema-box-title font-weight-bold"
                  >Data Feed Manager</span
                >
                <span class="schema-box-desc"
                  >Create, manage, and deploy ShareThis DaaS products </span
                >
              </div>
              <div class="schema-suboxes">
                  <div class="schema-subox">
                <a href="#">
                    <span class="schema-box-title font-weight-bold text-black"
                      >Event Feeds</span
                    >
                    <span class="schema-box-desc text-black"
                      >Create, manage, and deliver curated event feeds</span
                    >
                </a>
                  </div>
                  <div class="schema-subox ac-title">
                <a href="#">
                    <span class="schema-box-title font-weight-bold text-black"
                      >User Feeds</span
                    >
                    <span class="schema-box-desc text-black">
                      Create, manage, and deliver user-level data products
                    </span>
                </a>
                  </div>
              </div>
            </div>

            <div class="schema-side">
              <div class="schema-box ac-title">
                <span class="schema-box-title font-weight-bold"
                  >Data Discovery</span
                >
                <span class="schema-box-desc"
                  >Visualize and uncover key insights from your curated data feed</span
                >
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dropdownAudience :false,
      dropdownData :false
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#activation::v-deep {
  .custom-card{
    padding: 0 !important;
    background-color: #80808014 !important;
    max-width: 49% !important;
  }

  ul ul{
        list-style: circle  !important;
  }
  ul{
        list-style: inside  !important;
  }
  .ac.ac-card {
    border-radius: 3px;
  }
  .v-card {
    border-color: transparent !important;
    background: transparent;
  }
  .schema-box.ac-title{
    width: 100%;
  }
  .schema-container {
    display: grid;
    width: 100%;
    gap: 1rem;
    padding: 0 20px 20px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .audience .schema-box {
    background: #796cb1;
  }
  .explorer .schema-box {
    background: #207652;
  }
  .schema-box {
    width: 200px;
    height: 140px;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .schema-box * {
    color: #fff;
  }
  .schema-box-title {
    display: block;
    font-weight: 100;
    letter-spacing: 1px;
    margin-bottom: 8px;
  }
  .schema-box-desc {
    display: block;
    font-weight: 200;
    line-height: 1.3;
    font-size: 90%;
  }
  /* subbox */
    .explorer .schema-suboxes .schema-subox:hover * ,
  .audience .schema-suboxes .schema-subox:hover * {
    color: white;
  }
  .audience .schema-suboxes .schema-subox:hover {
    background-color: #796cb1;
  }
  .explorer .schema-suboxes .schema-subox:hover {
    background-color: #207652;
  }
  .schema-subox {
    position: relative;
    min-width: 180px;
    padding: 20px;
    border-radius: 5px;
    background: #ffffff;
    border: 1px solid #a1a1a1;
    margin-left: 9%;
    margin-bottom: 10px;
  }
  .audience .schema-subox::before {
    color: #796cb1;
  }
  .explorer .schema-subox::before {
    color: #207652;
  }
  .schema-subox::before {
    content: "→";
    position: absolute;
    left: -10%;
    font-size: 22px;
    top: 40%;
  }

  .audience .schema-subox:first-child::after {
    border-left: 2px solid #796cb1;
  }
  .explorer .schema-subox:first-child::after {
    border-left: 2px solid #207652;
  }

  .audience .schema-subox::after {
    border-left: 2px solid #796cb1;
  }
  .explorer .schema-subox::after {
    border-left: 2px solid #207652;
  }
  .schema-subox::after {
    content: "";
    position: absolute;
    height: 100%;
  }
  .audience .schema-subox:last-child::after {
    border-left: 2px solid #796cb1;
  }
  .explorer .schema-subox:last-child::after {
    border-left: 2px solid #207652;
  }
 
  .schema-subox::after {
     top: -58%;
    left: -10%;
    height: 115%;
  }
}
</style>